<template>
  <div class="page">
    <Nav-bar title="图片列表" left-arrow :safe-area-inset-top="true" :border="false" @click-left="goBack"
      :style="{ paddingTop: isNative ? '44px' : '' }" />
    <c-loading :loading="isLoading">
      <div class="content detail-content" v-if="listData.length > 0">
        <ul class="list">
          <li class="fx-row fx-center" v-for="(item, index) in listData" :key="index" @click="showPreview(item, index)">
            <div class="img" v-lazy:background-image="item"></div>
          </li>
        </ul>
      </div>
      <div class="content-no detail-content-no fx-col fx-c-center" v-if="listData.length === 0">
        <img class="no-bg" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/list-empty.png" />
        <p>暂无数据，敬请期待</p>
      </div>
    </c-loading>
  </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview, Lazyload, NavBar } from "huoli-ui";
Vue.use(ImagePreview);
Vue.use(Lazyload);

import service from "@/service";
import get from "@/utils/get";
import * as Utils from "@/service/utils";
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";

export default {
  data() {
    return {
      isLoading: true,
      scenicSpotId: "",
      //   date: Datetime.dateFormatFmt(new Date(), "yyyy-MM-dd"), // 当前查询价格的日期，不用于下单
      listData: [],
      previewImgs: [],
      typeOfBusiness: 0,
      isNative: env.isNative,
    };
  },
  components: {
    NavBar,
  },
  computed: {},
  created() {
    this.initQuery().then(() => {
      this.init();
    });
  },

  methods: {
    goBack() {
      // this.$router.back();
      this.$page.back();
    },
    // 将链接上的参数初始化到 store 中
    initQuery() {
      const {
        typeOfBusiness,
        scenicSpotId,
        destinationCode,
        productId,
        selectDate,
      } = this.$route.query;
      return new Promise((resolve) => {
        Utils.setTimeoutStoreFn(
          [
            () => {
              this.typeOfBusiness = typeOfBusiness || "";
              this.scenicSpotId = scenicSpotId || "";
              this.destinationCode = destinationCode || "";
              this.productId = productId || "";
              this.selectDate = selectDate || "";
            },
          ],
          () => {
            resolve(true);
          }
        );
      });
    },

    init() {
      this.loadData();
    },

    // 加载全部分类数据
    loadData() {
      this.isLoading = true;
      let params = {};
      if (this.typeOfBusiness == 1) {
        params = {
          scenicSpotId: this.scenicSpotId,
        };
        return service
          .r215044(params)
          .then((r) => get(r, "res.bd.data"))
          .then((r) => {
            if (r) {
              let imgs = r.images;
              // console.log(r.images)
              this.listData = imgs;
              this.previewImgs = imgs;
            }
            return r.images || [];
          })
          .catch((e) => {
            console.warn("[list] #loadData error: ", e);
            return null;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (this.typeOfBusiness == 2) {
        params = {
          destinationCode: this.destinationCode,
          productId: this.productId,
          selectDate: this.selectDate,
        };
        return service
          .r215041(params)
          .then((r) => get(r, "res.bd.data"))
          .then((r) => {
            console.log(r);
            if (r) {
              let imgs = r.images;
              // console.log(r.images)
              this.listData = imgs;
              this.previewImgs = imgs;
            }
            return r.images || [];
          })
          .catch((e) => {
            console.warn("[list] #loadData error: ", e);
            return null;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (this.typeOfBusiness == 3) {
        params = {
          productId: this.productId,
        };
        return service
          .r215050(params)
          .then((r) => get(r, "res.bd.data"))
          .then((r) => {
            if (r) {
              let imgs = r.images;
              // console.log(r.images)
              this.listData = imgs;
              this.previewImgs = imgs;
            }
            return r.images || [];
          })
          .catch((e) => {
            console.warn("[list] #loadData error: ", e);
            return null;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    showPreview(item, index) {
      ImagePreview({
        images: this.previewImgs,
        startPosition: index,
        closeable: false,
        showIndex: false,
      });
    },
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "1" });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/style";
@import "@/scss/common";

.page {
  min-height: 100%;
  background: #f6f6f6;

  .detail-content {
    padding: 10 * $px 6 * $px;

    ul {
      margin: 0;
      padding: 0;
      @include clear-fix;
    }

    li {
      // margin: 0 6 * $px 12 * $px;
      box-sizing: border-box;
      margin-bottom: 12 * $px;
      padding-right: 6 * $px;
      padding-left: 6 * $px;
      list-style: none;
      width: 50%;
      height: 165 * $px;
      overflow: hidden;
      float: left;

      .img {
        width: 100%;
        height: 165 * $px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 8 * $px;
      }

      p {
        margin: 0;
        font-size: 13 * $px;
        color: #666666;
        line-height: 18 * $px;
      }
    }
  }

  .detail-content-no {
    padding: 188 * $px 0 0 0;

    img {
      width: 280 * $px;
      height: 144 * $px;
    }

    p {
      margin: 25 * $px 0 0 0;
      font-size: 13 * $px;
      font-weight: 400;
      color: #3c4f62;
    }
  }
}
</style>
